import React, { useState, useEffect } from 'react';
import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Select,
	Switch,
	Spinner,
	FormErrorMessage,
	useToast,
} from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import { Select as ChakraReactSelect } from 'chakra-react-select';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { Countries } from '../remote_config/enums';
import { UserOverride } from '@repo/alictus-common/types/user';
import { Platform } from '@repo/alictus-common/enums/platform';
import userOverrideSchema from '@repo/alictus-common/validation/user_override_schema';
import axios from 'axios';
import config from '../config';
import globalStore from '../store';

interface UserOverrideModalProps {
	isOpen: boolean;
	onClose: () => void;
}

// Generate country list for dropdown but discard if country name is "All"
const countryList = Countries.filter((country) => country.code !== 'All').map((country) => ({ value: country.code, label: country.name }));

const UserOverrideModal: React.FC<UserOverrideModalProps> = ({ isOpen, onClose }) => {
	const toast = useToast();
	let globalData = globalStore();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [userOverrides, setUserOverrides] = useState<UserOverride[] | null>(null);
	const [errors, setErrors] = useState<{ [key: string]: string }>({});
	const [manualInput, setManualInput] = useState<string>('');
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const [isOverrideActive, setIsOverrideActive] = useState<boolean>(false);

	const [formData, setFormData] = useState<UserOverride>({
		id: 0,
		userId: 0,
		activateOverride: true,
		platform: Platform.iOS,
		deviceId: null,
		country: null,
		time: null,
		isAlictusUser: null,
		playfabId: null,
		newUser: null,
	});

	useEffect(() => {
		const fetchUserOverride = async () => {
			try {
				const response = await axios.get(`${config.API_ENDPOINT}/game/` + globalData.gameId + `/user_override`, {
					headers: { Authorization: 'Bearer ' + globalData.JWTToken },
				});

				const fetchedData: UserOverride[] = response.data;
				setUserOverrides(fetchedData);
			} catch (err) {
				console.error('An error occurred while fetching user override data:', err);
			} finally {
				setIsLoading(false);
			}
		};

		if (isOpen) {
			fetchUserOverride();
		}
	}, [isOpen]);

	useEffect(() => {
		if (userOverrides) {
			const platformData = userOverrides.find((data) => data.platform === formData.platform);
			if (platformData) {
				setFormData(platformData);
				setManualInput(platformData.time ? platformData.time.toString() : '');
				setIsOverrideActive(platformData.activateOverride);
			} else {
				setFormData({
					id: 0,
					userId: 0,
					activateOverride: true,
					platform: formData.platform,
					deviceId: null,
					country: null,
					time: null,
					isAlictusUser: null,
					playfabId: null,
					newUser: null,
				});
				setManualInput('');
				setIsOverrideActive(false);
			}
		}
	}, [formData.platform, userOverrides]);

	// Handle input changes and update state
	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		const newValue = name === 'platform' ? Number(value) : value;

		setFormData((prev) => {
			const updatedData = { ...prev, [name]: newValue };

			if (name === 'platform') {
				// When platform changes, check if data exists for it
				const platformData = userOverrides?.find((data) => data.platform === Number(value));
				if (platformData) {
					return platformData;
				} else {
					// Reset form if no data exists for the selected platform
					return {
						id: 0,
						userId: 0,
						activateOverride: false,
						platform: Number(value),
						deviceId: null,
						country: null,
						time: null,
						isAlictusUser: null,
						playfabId: null,
						newUser: null,
					};
				}
			}

			return updatedData;
		});
	};

	const handleManualInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setManualInput(value);

		if (!isNaN(Number(value)) && Number(value) >= 0) {
			const epochTime = Number(value);
			setFormData((prev) => ({ ...prev, time: epochTime }));
			setSelectedDate(new Date(epochTime * 1000)); // Sync selectedDate with manual input
		} else {
			setFormData((prev) => ({ ...prev, time: null }));
			setSelectedDate(null); // Clear selectedDate if manual input is invalid
		}
	};

	const handleDateChange = (date: Date | null) => {
		setSelectedDate(date);
		if (date) {
			const epochTime = Math.floor(date.getTime() / 1000); // Convert to seconds
			setFormData((prev) => ({ ...prev, time: epochTime }));
			setManualInput(epochTime.toString()); // Sync manual input with selected date
		} else {
			setFormData((prev) => ({ ...prev, time: null }));
		}
	};

	const handleIsAlictusUser = (value: string) => {
		const newValue = value === 'null' ? null : value === 'true';
		setFormData((prev) => ({ ...prev, isAlictusUser: newValue }));
	};

	const handleNewUserChange = (value: string) => {
		const newValue = value === 'null' ? null : value === 'true';
		setFormData((prev) => ({ ...prev, newUser: newValue }));
	};

	// Validate form data on submit
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const validatedData = userOverrideSchema.parse({
				...formData,
				time: formData.time ? Number(formData.time) : null,
			});

			const response = await axios.post(`${config.API_ENDPOINT}/game/` + globalData.gameId + `/user_override`, validatedData, {
				headers: { Authorization: 'Bearer ' + globalData.JWTToken },
			});

			if (response.status === 201) {
				openToast(
					'User Override Created Successfully',
					(formData.platform === 1 ? 'iOS' : 'Android') + ' User Override has been created successfully.',
					'success',
				);
			} else {
				openToast('Error', 'An error occurred while creating user override.', 'error');
			}
			handleClose();
		} catch (err: any) {
			if (err.issues) {
				// Collect validation errors
				const validationErrors: { [key: string]: string } = {};
				err.issues.forEach((issue: any) => {
					validationErrors[issue.path[0]] = issue.message;
				});
				setErrors(validationErrors);

				openToast('Validation Error', 'Please check the form for errors.', 'error');
			} else {
				console.error('An unexpected error occurred:', err);
				openToast('Error', 'An unexpected error occurred.', 'error');
			}
		}
	};

	const handleUpdate = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const validatedData = userOverrideSchema.parse({
				...formData,
				time: formData.time ? Number(formData.time) : null,
			});

			const response = await axios.put(
				`${config.API_ENDPOINT}/game/` + globalData.gameId + `/user_override/${formData.id}`,
				validatedData,
				{
					headers: { Authorization: 'Bearer ' + globalData.JWTToken },
				},
			);

			if (response.status === 200) {
				openToast(
					'User Override Updated Successfully',
					(formData.platform === 1 ? 'iOS' : 'Android') + ' User Override has been updated successfully.',
					'success',
				);
			} else {
				openToast('Error', 'An error occurred while updating user override.', 'error');
			}
			handleClose();
		} catch (err: any) {
			if (err.issues) {
				// Collect validation errors
				const validationErrors: { [key: string]: string } = {};
				err.issues.forEach((issue: any) => {
					validationErrors[issue.path[0]] = issue.message;
				});
				setErrors(validationErrors);

				openToast('Validation Error', 'Please check the form for errors.', 'error');
			} else {
				console.error('An unexpected error occurred:', err);

				openToast('Error', 'An unexpected error occurred.', 'error');
			}
		}
	};

	const openToast = (title: string, description: string, status: 'success' | 'error') => {
		toast({
			title,
			description,
			status,
			duration: 5000,
			position: 'top',
			isClosable: true,
		});
	};

	// Reset form and close modal
	const handleClose = () => {
		setFormData({
			id: 0,
			userId: 0,
			activateOverride: false,
			platform: Platform.iOS,
			deviceId: null,
			country: null,
			time: null,
			isAlictusUser: null,
			playfabId: null,
			newUser: null,
		});
		setErrors({});
		setManualInput('');
		setSelectedDate(null);
		onClose();
	};

	if (isLoading) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height="100%">
				<Spinner size="xl" />
			</Box>
		);
	}

	return (
		<Modal isOpen={isOpen} onClose={handleClose} size="lg">
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Manage User Override</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<form>
						{/* Platform ID Input */}
						<FormControl mb={4} isInvalid={!!errors.platform}>
							<FormLabel>Platform</FormLabel>
							<Select name="platform" value={formData.platform} onChange={(e) => handleChange(e as React.ChangeEvent<HTMLSelectElement>)}>
								<option value={Platform.iOS}>iOS</option>
								<option value={Platform.Android}>Android</option>
							</Select>
							{errors.platform && <FormErrorMessage>{errors.platform}</FormErrorMessage>}
						</FormControl>

						{/* Playfab ID Input */}
						<FormControl mb={4} isInvalid={!!errors.playfabId} opacity={isOverrideActive ? 1 : 0.5}>
							<FormLabel>Playfab ID</FormLabel>
							<Input
								name="playfabId"
								value={formData.playfabId || ''}
								onChange={handleChange}
								placeholder="Enter Playfab ID"
								isDisabled={!isOverrideActive}
							/>
							{errors.playfabId && <FormErrorMessage>{errors.playfabId}</FormErrorMessage>}
						</FormControl>

						{/* Device ID Input */}
						<FormControl mb={4} isInvalid={!!errors.deviceId} opacity={isOverrideActive ? 1 : 0.5}>
							<FormLabel>Device ID</FormLabel>
							<Input
								name="deviceId"
								value={formData.deviceId || ''}
								onChange={handleChange}
								placeholder="Enter Device ID"
								isDisabled={!isOverrideActive}
							/>
							{errors.deviceId && <FormErrorMessage>{errors.deviceId}</FormErrorMessage>}
						</FormControl>

						{/* Country Select */}
						<FormControl mb={4} isInvalid={!!errors.country} opacity={isOverrideActive ? 1 : 0.5}>
							<FormLabel>Country</FormLabel>
							<ChakraReactSelect
								options={[{ label: 'Select Country (null)', value: null }, ...countryList]}
								isMulti={false}
								placeholder="Select Country"
								value={
									formData.country
										? countryList.find((country) => country.value === formData.country)
										: { label: 'Select Country (null)', value: null }
								}
								onChange={(selectedOption) => setFormData((prev) => ({ ...prev, country: selectedOption?.value || null }))}
								isDisabled={!isOverrideActive}
							/>
							{errors.country && <FormErrorMessage>{errors.country}</FormErrorMessage>}
						</FormControl>

						{/* Time Input Field */}
						<FormControl mb={4} isInvalid={!!errors.time} opacity={isOverrideActive ? 1 : 0.5}>
							<FormLabel>Time (Epoch)</FormLabel>
							<div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
								<InputGroup>
									<Input
										type="number"
										step="1"
										value={manualInput}
										onChange={handleManualInputChange}
										placeholder="Enter epoch time"
										isDisabled={!isOverrideActive}
									/>
									<InputRightElement opacity={0.5} pointerEvents="none" minW={180}>
										{manualInput && !isNaN(Number(manualInput)) && Number(manualInput) > 0
											? new Date(Number(manualInput) * 1000).toLocaleString('tr-TR')
											: ''}
									</InputRightElement>
								</InputGroup>
								<DatePicker
									selected={selectedDate}
									onChange={handleDateChange}
									showTimeSelect
									timeIntervals={1}
									customInput={
										<Button size="sm" colorScheme="blue" leftIcon={<CalendarIcon />} isDisabled={!formData.activateOverride}>
											Pick
										</Button>
									}
								/>
							</div>
							{errors.time && <FormErrorMessage>{errors.time}</FormErrorMessage>}
						</FormControl>

						{/* Is Alictus User Dropdown */}
						<FormControl mb={4} isInvalid={!!errors.isAlictusUser} opacity={isOverrideActive ? 1 : 0.5}>
							<FormLabel>Is Alictus User</FormLabel>
							<Select
								value={formData.isAlictusUser === null ? 'null' : String(formData.isAlictusUser)}
								onChange={(e) => handleIsAlictusUser(e.target.value)}
								isDisabled={!isOverrideActive}
							>
								<option value="null">Select User Status</option>
								<option value="true">Yes</option>
								<option value="false">No</option>
							</Select>
							{errors.isAlictusUser && <FormErrorMessage>{errors.isAlictusUser}</FormErrorMessage>}
						</FormControl>

						{/* New User Dropdown */}
						<FormControl mb={4} isInvalid={!!errors.newUser} opacity={isOverrideActive ? 1 : 0.5}>
							<FormLabel>New User</FormLabel>
							<Select
								value={formData.newUser === null ? 'null' : String(formData.newUser)}
								onChange={(e) => handleNewUserChange(e.target.value)}
								isDisabled={!isOverrideActive}
							>
								<option value="null">Select New User</option>
								<option value="true">Yes</option>
								<option value="false">No</option>
							</Select>
							{errors.newUser && <FormErrorMessage>{errors.newUser}</FormErrorMessage>}
						</FormControl>

						{/* Activate Override Switch */}
						<FormControl mb={4} display="flex" alignItems="center" isInvalid={!!errors.activateOverride}>
							<FormLabel mb={0}>Activate Override</FormLabel>
							<Switch
								name="activateOverride"
								isChecked={isOverrideActive}
								onChange={() => {
									const newValue = !isOverrideActive;
									setIsOverrideActive(newValue);
									setFormData((prev) => ({ ...prev, activateOverride: newValue }));
								}}
							/>
							{errors.activateOverride && <FormErrorMessage>{errors.activateOverride}</FormErrorMessage>}
						</FormControl>

						{/* Submit Button */}
						<ModalFooter>
							{userOverrides?.some((override) => override.platform === formData.platform) ? (
								<Button type="button" colorScheme="blue" mr={3} onClick={handleUpdate}>
									Update
								</Button>
							) : (
								<Button type="submit" colorScheme="blue" mr={3} onClick={handleSubmit}>
									Submit
								</Button>
							)}
							<Button onClick={handleClose}>Cancel</Button>
						</ModalFooter>
					</form>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default UserOverrideModal;
