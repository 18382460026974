import React, { useState } from 'react';
import { Button, Flex, FormControl, Select, Heading } from '@chakra-ui/react';

function UserDropdown({ onListSessions, onResetSessions, userList }) {
	const [selectedUser, setSelectedUser] = useState(null);
	const [deviceList, setDeviceList] = useState([]);
	const [isDeviceDropdownEnabled, setIsDeviceDropdownEnabled] = useState(false);
	const [selectedDevice, setSelectedDevice] = useState(null);
	const [allDevicesSelected, setAllDevicesSelected] = useState(false);

	// Function to reset state when the reset button is clicked
	const handleResetClick = () => {
		setSelectedUser(null);
		setDeviceList([]);
		setIsDeviceDropdownEnabled(false);
		setSelectedDevice(null);
		setAllDevicesSelected(false);
		onResetSessions(); // Trigger reset in the parent component
	};

	const handleUserClick = (user) => {
		setSelectedUser(user);
		console.log('Selected user:', user);

		const fetchedDevices = user.device_ids || [];
		setDeviceList(fetchedDevices);
		setIsDeviceDropdownEnabled(fetchedDevices.length > 0);
		setSelectedDevice(null);
		setAllDevicesSelected(false);
	};

	const handleDeviceClick = (device) => {
		setSelectedDevice(device);
		setAllDevicesSelected(false);
		console.log('Selected device:', device);
	};

	const handleAllDevicesClick = () => {
		setAllDevicesSelected(true);
		setSelectedDevice(null);
		console.log('Selected all devices');
	};

	// Function to trigger when the user selects "List Sessions"
	const handleListSessionsClick = () => {
		const selectedDevices = allDevicesSelected ? deviceList.map((device) => device.device_id) : [selectedDevice.device_id];
		onListSessions(selectedUser.name, selectedDevices); // Pass selected user and devices
	};

	return (
		<Flex direction="row" position="relative" gap={4} paddingTop={'5px'}>
			<FormControl width="10%">
				<Heading size="md" paddingLeft={'20px'} paddingTop={'10px'}>
					Filter User
				</Heading>
			</FormControl>
			<FormControl width="25%">
				<Select
					width="100%"
					placeholder="Select a user"
					value={selectedUser ? selectedUser._id : ''}
					onChange={(e) => {
						const selectedUser = userList.find((user) => user._id === e.target.value);
						if (selectedUser) {
							handleUserClick(selectedUser);
						}
					}}
				>
					{userList.map((user) => (
						<option key={user._id} value={user._id}>
							{user.name}
						</option>
					))}
				</Select>
			</FormControl>
			<FormControl width="25%">
				<Select
					width="100%"
					placeholder="Select a device"
					isDisabled={!isDeviceDropdownEnabled}
					value={allDevicesSelected ? 'all' : selectedDevice ? selectedDevice.device_id : ''}
					onChange={(e) => {
						if (e.target.value === 'all') {
							handleAllDevicesClick();
						} else {
							const selectedDevice = deviceList.find((device) => device.device_id === e.target.value);
							if (selectedDevice) {
								handleDeviceClick(selectedDevice);
							}
						}
					}}
				>
					<option value="all">All Devices</option>
					{deviceList.map((device) => (
						<option key={device.device_id} value={device.device_id}>
							{device.device_name || 'Unnamed Device'} (ID: {device.device_id})
						</option>
					))}
				</Select>
			</FormControl>
			<Button onClick={handleListSessionsClick} isDisabled={!selectedUser || (!selectedDevice && !allDevicesSelected)}>
				List Sessions
			</Button>
			<Button onClick={handleResetClick}>Reset</Button>
		</Flex>
	);
}

export default UserDropdown;
