import React, { useState } from 'react';
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Button,
	Input,
	Text,
	Flex,
	Textarea,
	Icon,
	Tooltip,
	Menu,
	MenuButton,
	MenuItem,
	MenuGroup,
	MenuList,
	IconButton,
	useToast,
} from '@chakra-ui/react';
import { SearchIcon, ViewIcon } from '@chakra-ui/icons';
import { FaTrashAlt } from 'react-icons/fa';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi';
import { CgCloseO } from 'react-icons/cg';
import { RemoteConfigValue } from '@repo/alictus-common/types';
import { ViewFilter } from '../view_filter';
import { GlobalStore } from '../../store';
import { RemoteConfigTypeToString, formatTimestamp } from '../../utility';
import { FilterToString } from '../utils/filter_utils';
import { toggleRCValueStatus } from '../utils/remote_config_utils';
import { PlayerSegment } from '@repo/alictus-common/types/segment';
import config from '../../config';
import axios from 'axios';

interface RemoteConfigTableProps {
	globalData: GlobalStore;
	viewFilter: ViewFilter;
	editRCVariableDisclosure: any;
	deleteRCKeyDisclosure: any;
	setDeleteRCKeyData: (data: any) => void;
	multiFunction: (disc: { onOpen: () => void }, remoteConfigValue: RemoteConfigValue) => void;
	segmentationData: PlayerSegment[];
	environment: string;
}

export function RemoteConfigTable({
	globalData,
	viewFilter,
	editRCVariableDisclosure,
	deleteRCKeyDisclosure,
	setDeleteRCKeyData,
	multiFunction,
	segmentationData,
	environment,
}: RemoteConfigTableProps) {
	const [sortField, setSortField] = useState<'key' | 'lastUpdated' | 'type' | 'isTest' | 'isPersonal' | 'isActive'>('key');
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
	const [searchQuery, setSearchQuery] = useState(''); // State for search query
	const [isSearchActive, setIsSearchActive] = useState(false);
	const toast = useToast();

	const handleSort = (field: 'key' | 'lastUpdated' | 'type' | 'isTest' | 'isPersonal' | 'isActive') => {
		if (sortField === field) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortField(field);
			setSortDirection('asc');
		}
	};

	function sendCreateRequest(data: RemoteConfigValue | undefined) {
		console.log('Inside sendCreateRequest');
		console.log(data);
		if (!data) return;
		data.key = data.key + ' Copy';

		const request = axios.post(`${config.API_ENDPOINT}/game/${globalData.gameId}/rc_package/1/rc_value`, data, {
			headers: { Authorization: `Bearer ${globalData.JWTToken}` },
		});

		request
			.then((res) => {
				console.log('Success:', res);
				toast({
					title: 'Remote Config Value Duplicated',
					description: 'The Remote Config Value has been duplicated successfully.',
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'top',
				});
			})
			.catch((err) => {
				console.error('Error:', err);
				toast({
					title: 'Error',
					description: err.response?.data || 'An error occurred while duplicating the Remote Config Value.',
					status: 'error',
					duration: 5000,
					isClosable: true,
					position: 'top',
				});
			});
	}

	async function downloadRCValue(rc_id: string): Promise<RemoteConfigValue | null> {
		console.log('Download RC Value');
		try {
			const response = await axios.get(`${config.API_ENDPOINT}/game/${globalData.gameId}/rc_package/1/rc_value/${rc_id}`, {
				headers: { Authorization: `Bearer ${globalData.JWTToken}` },
			});
			return response.data as RemoteConfigValue;
		} catch (error) {
			console.error('Error fetching full remote config:', error);
			if (axios.isAxiosError(error)) {
				if (error.response) {
					console.error('Response data:', error.response.data);
					console.error('Response status:', error.response.status);
				} else if (error.request) {
					console.error('No response received:', error.request);
				} else {
					console.error('Error setting up request:', error.message);
				}
			} else {
				console.error('Unexpected error:', error);
			}
			return null;
		}
	}

	async function downloadRCValueText(data: RemoteConfigValue | undefined) {
		console.log('Download RC Text');

		if (!data) return;

		const rcValue = await downloadRCValue(String(data.id));
		console.log(rcValue);
		if (!rcValue) {
			toast({
				title: 'Error',
				description: 'An error occurred while fetching the Remote Config Value.',
				status: 'error',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});
			return;
		}

		let parsedValue = {};
		let isValueValid = false;
		try {
			parsedValue = JSON.parse(rcValue.value);

			toast({
				title: 'Remote Config Value Downloaded',
				description: 'The Remote Config Value has been downloaded successfully.',
				status: 'success',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});
			isValueValid = true;
		} catch (error) {
			// If the value is not a valid JSON, show an error toast
			// and save the value as is

			toast({
				title: 'Error',
				description: 'The Remote Config Value is not a valid JSON. Downloading as is.',
				status: 'warning',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});

			parsedValue = rcValue.value;
		}
		const jsonContent = JSON.stringify(parsedValue, null, 2);
		const blob = new Blob([jsonContent], { type: 'application/json' });
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = isValueValid ? `${rcValue.key}.json` : `${rcValue.key}.txt`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const filteredData = (globalData.remoteConfigValues || []).filter((item) => item.key.toLowerCase().includes(searchQuery.toLowerCase()));
	let filteredDataByEnvironment = filteredData.filter((item) => item.isTest === (environment === 'development'));

	const sortedData = filteredDataByEnvironment.sort((a, b) => {
		let result = 0;
		if (sortField === 'key') {
			result = a.key.localeCompare(b.key);
		} else if (sortField === 'lastUpdated') {
			result = new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime();
		} else if (sortField === 'type') {
			result = RemoteConfigTypeToString(a.type).localeCompare(RemoteConfigTypeToString(b.type));
		} else if (sortField === 'isTest' || sortField === 'isPersonal' || sortField === 'isActive') {
			result = (a[sortField] ? 1 : 0) - (b[sortField] ? 1 : 0);
		}
		return sortDirection === 'asc' ? result : -result;
	});

	return (
		<TableContainer>
			<Table variant="simple" size="md" border={'0px'}>
				<Thead sx={{ th: { textAlign: 'center', verticalAlign: 'middle' } }}>
					<Tr>
						<Th width="200px" cursor="pointer" maxW="200px" minW="200px">
							<Flex align="center" gap="15px" height="20px">
								<Tooltip label="Search by Name" placement="top">
									<Icon
										as={SearchIcon}
										boxSize={4}
										cursor="pointer"
										onClick={(e) => {
											e.stopPropagation();
											setIsSearchActive(!isSearchActive);
										}}
									/>
								</Tooltip>
								{isSearchActive && (
									<Input
										size="sm"
										placeholder="Search..."
										value={searchQuery}
										onChange={(e) => setSearchQuery(e.target.value)}
										autoFocus
										width="100%"
									/>
								)}
								{!isSearchActive && (
									<span onClick={() => handleSort('key')}>Name {sortField === 'key' && (sortDirection === 'asc' ? '↑' : '↓')}</span>
								)}
							</Flex>
						</Th>
						<Th width="200px">Description</Th>
						<Th width="200px">Value</Th>
						<Th width="100px" onClick={() => handleSort('type')} cursor="pointer">
							Type {sortField === 'type' && (sortDirection === 'asc' ? '↑' : '↓')}
						</Th>
						<Th width="250px">Filter</Th>
						<Th width="200px" onClick={() => handleSort('lastUpdated')} cursor="pointer">
							Latest Update {sortField === 'lastUpdated' && (sortDirection === 'asc' ? '↑' : '↓')}
						</Th>
						<Th width="150px" onClick={() => handleSort('isTest')} cursor="pointer">
							Production {sortField === 'isTest' && (sortDirection === 'asc' ? '↑' : '↓')}
						</Th>
						<Th width="150px" onClick={() => handleSort('isPersonal')} cursor="pointer">
							Public {sortField === 'isPersonal' && (sortDirection === 'asc' ? '↑' : '↓')}
						</Th>
						<Th width="150px" onClick={() => handleSort('isActive')} cursor="pointer">
							Status {sortField === 'isActive' && (sortDirection === 'asc' ? '↑' : '↓')}
						</Th>
						<Th width="50px">View</Th>
						<Th width="50px">Delete</Th>
						<Th width="50px">Action</Th>
					</Tr>
				</Thead>
				<Tbody>
					{sortedData.map(
						(field, i) =>
							viewFilter.canDisplay(field) && (
								<TableRow
									key={i}
									field={field}
									globalData={globalData}
									editRCVariableDisclosure={editRCVariableDisclosure}
									deleteRCKeyDisclosure={deleteRCKeyDisclosure}
									setDeleteRCKeyData={setDeleteRCKeyData}
									multiFunction={multiFunction}
									segmentationData={segmentationData}
									duplicateFunction={sendCreateRequest}
									downloadFunction={downloadRCValueText}
								/>
							),
					)}
				</Tbody>
			</Table>
		</TableContainer>
	);
}

interface TableRowProps {
	field: RemoteConfigValue;
	globalData: GlobalStore;
	editRCVariableDisclosure: any;
	deleteRCKeyDisclosure: any;
	setDeleteRCKeyData: (data: any) => void;
	multiFunction: (disc: { onOpen: () => void }, remoteConfigValue: RemoteConfigValue) => void;
	segmentationData: PlayerSegment[];
	duplicateFunction: (data: RemoteConfigValue) => void;
	downloadFunction: (data: RemoteConfigValue) => void;
}

function TableRow({
	field,
	globalData,
	editRCVariableDisclosure,
	deleteRCKeyDisclosure,
	setDeleteRCKeyData,
	multiFunction,
	segmentationData,
	duplicateFunction,
	downloadFunction,
}: TableRowProps) {
	return (
		<Tr>
			<Td
				textAlign="left"
				verticalAlign="middle"
				width="200px"
				maxW={'200px'}
				overflow="hidden"
				textOverflow="ellipsis"
				whiteSpace="nowrap"
				_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
			>
				{field.key}
			</Td>
			<Td
				textAlign="center"
				verticalAlign="middle"
				width={'200px'}
				maxW={'200px'}
				overflow="hidden"
				textOverflow="ellipsis"
				whiteSpace="nowrap"
				position="relative"
				_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
			>
				{field.description}
			</Td>
			<Td
				textAlign="center"
				verticalAlign="middle"
				width="200px"
				maxW="200px"
				overflow="hidden"
				textOverflow="ellipsis"
				whiteSpace="nowrap"
			>
				<Text maxW="200px" isTruncated>
					{String(field.value)}
				</Text>
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="100px">
				{RemoteConfigTypeToString(field.type)}
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="250px">
				{field.filterOperations.length > 0 && (
					<Textarea
						minW="250px"
						fontSize={'sm'}
						border={'0px'}
						value={FilterToString(field.filterOperations, segmentationData)}
						isReadOnly
					/>
				)}
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="200px">
				<Tooltip label={`Last updated by: ${field.lastUpdater}`} placement="top">
					<span>{formatTimestamp(field.lastUpdated)}</span>
				</Tooltip>
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="150px">
				{showCheckMark(!field.isTest)}
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="150px">
				{showCheckMark(!field.isPersonal)}
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="150px">
				<Button
					onClick={() => toggleRCValueStatus(globalData, field.id)}
					leftIcon={
						<Icon
							as={field.isActive ? BsFillCheckCircleFill : CgCloseO}
							color={field.isActive ? 'green.200' : 'red.200'}
							_hover={{ color: 'gray.300' }}
						/>
					}
					variant="simple"
				/>
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="50px">
				<ViewButton onOpen={() => multiFunction(editRCVariableDisclosure, field)} />
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="50px">
				<Button
					onClick={() => {
						setDeleteRCKeyData(field);
						deleteRCKeyDisclosure.onOpen();
					}}
					leftIcon={<Icon as={FaTrashAlt} color={'gray.200'} _hover={{ color: 'gray.300' }} />}
					variant="simple"
				/>
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="50px">
				<Menu>
					<MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
						<IconButton
							aria-label="Add"
							icon={<PiDotsThreeOutlineVerticalFill color={'gray.500'} />}
							backgroundColor={'transparent'}
							_hover={{ bg: 'transparent', color: 'white' }}
						/>
					</MenuButton>
					<MenuList>
						<MenuGroup title="Experimental Actions" fontSize={'m'} fontWeight={'bold'} color={'orange.500'}>
							<MenuItem onClick={() => duplicateFunction(field)}>Duplicate RC Value</MenuItem>

							<MenuItem onClick={() => downloadFunction(field)}>Download RC Text</MenuItem>
						</MenuGroup>
					</MenuList>
				</Menu>
			</Td>
		</Tr>
	);
}

function showCheckMark(isActive: boolean) {
	return (
		<Flex justify="center" align="center" width="100%" height="100%">
			<Icon as={isActive ? BsFillCheckCircleFill : CgCloseO} color={isActive ? 'green.200' : 'red.200'} _hover={{ color: 'gray.300' }} />
		</Flex>
	);
}

function ViewButton({ onOpen }: { onOpen: () => void }) {
	return (
		<Button
			onClick={onOpen}
			aria-label="View"
			leftIcon={<ViewIcon color={'gray.500'} _hover={{ color: 'gray.300' }} />}
			backgroundColor={'transparent'}
			_hover={{ bg: 'transparent', color: 'white' }}
		/>
	);
}
