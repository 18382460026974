import { z } from 'zod';
import { Platform } from '../types';

const deviceIdRegex = /^[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}$/i;

const userOverrideSchema = z
	.object({
		activateOverride: z.boolean(),
		platform: z.nativeEnum(Platform),
		deviceId: z.string().optional().nullable(),
		country: z.string().optional().nullable(),
		time: z.number().nullable().optional(),
		isAlictusUser: z.boolean().nullable(),
		playfabId: z.string().optional().nullable(),
		newUser: z.boolean().nullable(),
	})
	.superRefine((data, ctx) => {
		const { deviceId, playfabId } = data;

		if (playfabId && playfabId.includes(' ')) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'Playfab ID cannot contain spaces',
				path: ['playfabId'],
			});
		}

		if (deviceId) {
			if (deviceId.includes(' ')) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Device ID cannot contain spaces',
					path: ['deviceId'],
				});
			}

			if (!deviceIdRegex.test(deviceId)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Device ID must be a valid UUID',
					path: ['deviceId'],
				});
			}
		}
	});

export default userOverrideSchema;
