import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { PlusSquareIcon } from '@chakra-ui/icons';

interface PlusButtonProps {
	paddingTop?: string;
	onOpen: () => void;
	environment: string;
}

export function PlusButton({ paddingTop, onOpen, environment }: PlusButtonProps) {
	if (environment === 'production') {
		return null; // Hide the button in production
	}
	return (
		<IconButton
			onClick={onOpen}
			paddingTop={paddingTop}
			aria-label="Add"
			icon={<PlusSquareIcon color={'gray.500'} _hover={{ color: 'gray.300' }} />}
			backgroundColor={'transparent'}
			_hover={{ bg: 'transparent', color: 'white' }}
		/>
	);
}
