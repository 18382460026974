import {
	Box,
	Flex,
	Avatar,
	HStack,
	IconButton,
	Button,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useColorModeValue,
	Image,
	Stack,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import LogView from './logger/log_view';
import AddDeviceIdModal from './profile/add_device_id';
import { useState } from 'react';
import RemoteConfig from './remote_config/remote_config_view';
import BuildsPage from './builds/build_view';
import ABTestView from './ab_test/ab_test_view';
import { useDisclosure } from '@chakra-ui/hooks';
import SegmentationView from './segment/segment_view';
import SelectorView from './selector/selector_view';
import UpdatePasswordModal from './profile/UpdatePasswordModal';
import GetAlictusIdModal from './profile/convert_playfab_id_to_alictus_id';
import UserOverrideModal from './profile/user_override';
import usePeriodicJwtRefresh from './hooks/usePeriodicJwtRefreshHook';

const Links = [
	'Dashboard',
	'Live Logger',
	'Remote Config-PROD',
	'Remote Config-DEV',
	'Builds',
	'AB Test',
	'Segmentation',
	'Data Selectors',
];

const NavLink = ({ children, setSelectedLink }) => {
	return (
		<Box
			as="a"
			px={2}
			py={1}
			rounded={'md'}
			_hover={{
				textDecoration: 'none',
				bg: useColorModeValue('gray.200', 'gray.700'),
			}}
			href={'#'}
			onClick={() => setSelectedLink(children)}
		>
			{children}
		</Box>
	);
};

export function NavBar({ userToken, setUserToken, avatarUrl, roles }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const addDeviceDisclosure = useDisclosure();
	const getAlictusIdDisclosure = useDisclosure();
	const userOverrideDisclosure = useDisclosure();
	const [selectedGameId, setSelectedGameId] = useState('com.ck.tripletiles');
	const [selectedLink, setSelectedLink] = useState('Live Logger');
	const [activeComponent, setActiveComponent] = useState('Live Logger');
	const updatePasswordDisclosure = useDisclosure();
	usePeriodicJwtRefresh();

	// Function to check if a user has a specific role
	const hasRole = (role) => roles.includes(role);

	const handleLogout = () => {
		try {
			setUserToken(null);
			sessionStorage.removeItem('dashboardUserInfo'); // Remove dashboardUserInfo from local storage
		} catch (error) {
			console.error('Logout error:', error);
		}
	};

	// Filter links based on roles
	const availableLinks = Links.filter((link) => {
		if (hasRole('ADMIN')) {
			return true;
		}

		if (hasRole('HUB_USER')) {
			return link === 'Dashboard' || link === 'Live Logger';
		}

		if (hasRole('PRODUCT')) {
			return link !== 'Builds';
		}

		if (hasRole('DEVELOPER')) {
			return link !== 'Segmentation' && link !== 'Data Selectors' && link !== 'Remote Config-PROD';
		}

		// Deny access to all links if no roles match
		return false;
	});

	return (
		<>
			<Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
				<Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
					<IconButton
						size={'md'}
						icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
						aria-label={'Open Menu'}
						display={{ md: 'none' }}
						onClick={isOpen ? onClose : onOpen}
					/>
					<HStack spacing={8} alignItems={'center'}>
						<Box>
							<Image src="logo512.png" width={'40px'} />
						</Box>
						<HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
							{availableLinks.map((link) => (
								<NavLink
									setSelectedLink={(link) => {
										setSelectedLink(link);
										setActiveComponent(link);
									}}
									key={link}
								>
									{link}
								</NavLink>
							))}
						</HStack>
					</HStack>
					<Flex alignItems={'center'}>
						<Menu>
							<MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
								<Avatar size={'sm'} src={avatarUrl} />
							</MenuButton>
							<MenuList>
								{addDeviceDisclosure.isOpen && (
									<AddDeviceIdModal isOpen={addDeviceDisclosure.isOpen} onClose={addDeviceDisclosure.onClose} />
								)}
								{getAlictusIdDisclosure.isOpen && (
									<GetAlictusIdModal isOpen={getAlictusIdDisclosure.isOpen} onClose={getAlictusIdDisclosure.onClose} />
								)}
								{userOverrideDisclosure.isOpen && (
									<UserOverrideModal isOpen={userOverrideDisclosure.isOpen} onClose={userOverrideDisclosure.onClose} />
								)}
								<MenuItem onClick={() => addDeviceDisclosure.onOpen()}>Add DeviceId</MenuItem>
								<MenuItem onClick={() => updatePasswordDisclosure.onOpen()}>Update Password</MenuItem>
								<MenuItem onClick={() => getAlictusIdDisclosure.onOpen()}>Get Playfab Id</MenuItem>
								<MenuItem onClick={() => userOverrideDisclosure.onOpen()}>User Override</MenuItem>
								<MenuItem onClick={handleLogout}>Logout</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
				</Flex>

				<UpdatePasswordModal isOpen={updatePasswordDisclosure.isOpen} onClose={updatePasswordDisclosure.onClose} />

				{isOpen ? (
					<Box pb={4} display={{ md: 'none' }}>
						<Stack as={'nav'} spacing={4}>
							{availableLinks.map((link) => (
								<NavLink key={link}>{link}</NavLink>
							))}
						</Stack>
					</Box>
				) : null}
			</Box>

			{/* Conditionally render components based on activeComponent and roles */}
			{activeComponent === 'Dashboard' && (hasRole('ADMIN') || hasRole('HUB_USER') || hasRole('PRODUCT') || hasRole('DEVELOPER')) && (
				<LogView userToken={userToken} selectedGameId={selectedGameId} />
			)}
			{activeComponent === 'Live Logger' && (hasRole('ADMIN') || hasRole('HUB_USER') || hasRole('PRODUCT') || hasRole('DEVELOPER')) && (
				<LogView userToken={userToken} selectedGameId={selectedGameId} />
			)}
			{activeComponent === 'Remote Config-PROD' && (hasRole('ADMIN') || hasRole('PRODUCT')) && (
				<RemoteConfig environment={'production'} header={'Remote Config - PROD'} />
			)}
			{activeComponent === 'Remote Config-DEV' && (hasRole('ADMIN') || hasRole('PRODUCT') || hasRole('DEVELOPER')) && (
				<RemoteConfig environment={'development'} header={'Remote Config - DEV'} />
			)}
			{activeComponent === 'AB Test' && (hasRole('ADMIN') || hasRole('PRODUCT') || hasRole('DEVELOPER')) && (
				<ABTestView userToken={userToken} selectedGameId={selectedGameId} />
			)}
			{activeComponent === 'Segmentation' && (hasRole('ADMIN') || hasRole('PRODUCT')) && <SegmentationView />}
			{activeComponent === 'Data Selectors' && (hasRole('ADMIN') || hasRole('PRODUCT')) && <SelectorView />}
			{activeComponent === 'Builds' && (hasRole('ADMIN') || hasRole('DEVELOPER')) && <BuildsPage />}
		</>
	);
}

export default NavBar;
